export interface IShoppingListRow {
  id: number;
  text: string;
  amount: string;
  comment: string;
}

export class ShoppingListRow {
  public id!: number;
  public text!: string;
  public amount!: string;
  public comment!: string;

  constructor(shoppingListRow?: IShoppingListRow) {
    if (shoppingListRow) {
      Object.assign(this, shoppingListRow);
    }
  }
}
