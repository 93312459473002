import { App } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

export function fontAwesome(app: App): void {
  app.component("fa-icon", FontAwesomeIcon);
}

import {
  faPlusCircle,
  faTrashAlt,
  faHome,
  faCopy,
  faListAlt,
} from "@fortawesome/free-solid-svg-icons";

library.add(faTrashAlt);
library.add(faPlusCircle);
library.add(faHome);
library.add(faCopy);
library.add(faListAlt);
