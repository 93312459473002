import { ShoppingList, IShoppingList } from "@/classes";
import axios from "axios";

export class ShoppingListService {
  public async create(name: string): Promise<ShoppingList> {
    const { data } = await axios.post<IShoppingList>("/api/shoppingLists", {
      name,
    });
    return new ShoppingList(data);
  }

  public async copy(newName: string, src: ShoppingList): Promise<ShoppingList> {
    const { data } = await axios.post<IShoppingList>(
      `/api/shoppingLists/${src.id}/copy`,
      {
        newName,
      }
    );
    return new ShoppingList(data);
  }

  public async getAll(): Promise<ShoppingList[]> {
    const { data } = await axios.get<IShoppingList[]>("/api/shoppingLists");
    return data.map((x: any) => new ShoppingList(x));
  }

  public async getOne(id: number): Promise<ShoppingList> {
    const { data } = await axios.get<IShoppingList>(`/api/shoppingLists/${id}`);
    return new ShoppingList(data);
  }

  public async previewOne(id: number): Promise<ShoppingList> {
    const { data } = await axios.get<IShoppingList>(
      `/api/shoppingLists/${id}/preview`
    );
    return new ShoppingList(data);
  }

  public async save(shoppingList: ShoppingList): Promise<ShoppingList> {
    const { data } = await axios.put<IShoppingList>(
      `/api/shoppingLists/${shoppingList.id}`,
      shoppingList
    );
    return new ShoppingList(data);
  }

  public async delete(shoppingList: ShoppingList): Promise<boolean> {
    await axios.delete(`/api/shoppingLists/${shoppingList.id}`);
    return true;
  }
}
