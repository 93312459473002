
import { Options, Vue } from "vue-class-component";
import axios from "axios";

interface ServerStatus {
  status: string;
  version: string;
}

@Options({})
export default class NavBar extends Vue {
  public version: string = "";

  public async mounted() {
    const { data } = await axios.get<ServerStatus>("/api/status");
    this.version = data.version;
  }
}
