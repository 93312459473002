import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import HomeView from "@/views/Home.vue";
import ShopingListView from "@/views/ShoppingList.vue";
import CreateShopingListView from "@/views/CreateShoppingList.vue";
import OrderView from "@/views/Order.vue";
import CreateOrderView from "@/views/CreateOrder.vue";
import DeleteShoppingList from "@/views/DeleteShoppingList.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/shoppingList/create",
    name: "create-shopping-list",
    component: CreateShopingListView,
  },
  {
    path: "/shoppingList/:id",
    name: "view-shopping-list",
    component: ShopingListView,
  },
  {
    path: "/shoppingList/:id/delete",
    name: "delete-shopping-list",
    component: DeleteShoppingList,
  },
  {
    path: "/order/create",
    name: "create-order",
    component: CreateOrderView,
  },
  {
    path: "/order/:id",
    name: "view-order",
    component: OrderView,
    props: true,
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(_to, _from, _savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});
