
import { Modal } from "bootstrap";
import { Options, Vue } from "vue-class-component";
import { ShoppingList, ShoppingListRow } from "../classes";
import { ShoppingListService } from "../services";

@Options({})
export default class ShoppingListView extends Vue {
  public shoppingList: ShoppingList | null = null;
  private shoppingListService: ShoppingListService = new ShoppingListService();

  public async mounted() {
    if (!this.$route.params.id) {
      this.$router.push({ name: "home" });
    }

    const id = parseInt(this.$route.params.id as string, 10) || -1;
    if (id === -1) {
      this.$router.push({ name: "home" });
    }

    this.shoppingListService
      .getOne(id)
      .then((shoppingList) => {
        this.shoppingList = shoppingList;
      })
      .catch((err) => {
        window.console.log(err);
        if (err.response && err.response.status === 404) {
          this.$router.push({
            name: "home",
          });
        }
      });
  }

  public addDefaultItems() {
    if (!this.shoppingList) {
      return;
    }

    const items = [
      { text: "Mellanmjölk, 1l frp, ekologisk" },
      { text: "" },
      { text: "Havremjölk, Oatly iKaffe" },
      { text: "Filmjölk, 3%, ekologisk" },
      { text: "Vaniljyoghurt, mindre socker" },
      { text: "Bregott, 300gr, små frp, ekologiskt" },
      { text: "Milda margarin" },
      { text: "Mjölkfritt smör, Flora" },
      { text: "" },
      { text: "" },
      { text: "Kalles kaviar" },
      { text: "Leverpastej, bredbar" },
      { text: "Ost, färdigskivad, storpack" },
      { text: "Ost, hårdost, olika sorter" },
      { text: "Ägg 6-pack, närproducerade" },
      { text: "Rökt skinka, storpack" },
      { text: "Rökt medwurst, storpack" },
      { text: "Kalkon, pålägg" },
      { text: "Kycklingpastej" },
      { text: "" },
      { text: "" },
      { text: "Delikatessknäcke, dubbel" },
      { text: "Havreknäcke, dubbel" },
      { text: "Falu rågrut, dubbel" },
      { text: "Frukostknäcke, dubbel" },
      { text: "" },
      { text: "" },
      { text: "Schär glutenfritt Surdegsbröd med lingon" },
      { text: "Schär glutenfri Classic Surdegsbröd" },
      { text: "" },
      { text: "" },
      { text: "Ketchup, Felix sockerfri" },
      { text: "Senap" },
      { text: "" },
      { text: "" },
      { text: "Bananer, KRAV" },
      { text: "Blandad frukt, svenskt om det finns" },
      { text: "Morötter" },
      { text: "Gurka" },
      { text: "Paprika" },
      { text: "" },
      { text: "" },
      { text: "Corn Flakes, ej Kellogs" },
      { text: "Flingor, havrefras" },
      { text: "Kaffe, brygg mellanrost" },
      { text: "Kaffefilter, 1+4" },
      { text: "Te, påsar" },
      { text: "Pumptvål" },
      { text: "" },
      { text: "" },
      { text: "Vetemjöl" },
      { text: "Socker" },
      { text: "Bitsocker" },
      { text: "Salt" },
      { text: "Citronsyra" },
      { text: "Karamellfärg" },
    ];

    for (const item of items) {
      const row: ShoppingListRow = this.shoppingList.addRow();
      row.text = item.text;
    }
  }

  public removeRow(idx: number) {
    if (this.shoppingList) {
      this.shoppingList.removeRow(idx);
    }
  }

  public async save() {
    if (this.shoppingList) {
      const shoppingList = await this.shoppingListService.save(
        this.shoppingList
      );
      this.shoppingList = shoppingList;

      const confirmationModal = new Modal(
        document.getElementById("saveConfirmation")!
      );
      confirmationModal.show();
    }
  }

  public async saveAndSubmit() {
    if (this.shoppingList) {
      const shoppingList = await this.shoppingListService.save(
        this.shoppingList
      );

      this.$router.push({
        name: "create-order",
        query: {
          shoppingList: `${shoppingList.id}`,
        },
        params: {
          shoppingList: JSON.stringify(shoppingList),
        },
      });
    }
  }
}
