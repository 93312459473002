import { IOrder, IShoppingListRow, ShoppingListRow } from ".";
import moment from "moment";

export interface IShoppingList {
  id: number;
  name: string;
  createdAt: moment.Moment;
  comment?: string;
  orderId?: number;
  order?: IOrder;
  rows: IShoppingListRow[];
}

export class ShoppingList implements IShoppingList {
  public id!: number;
  public name!: string;
  public createdAt!: moment.Moment;
  public comment?: string;
  public orderId?: number;
  public rows!: ShoppingListRow[];

  constructor(shoppingList?: IShoppingList) {
    if (shoppingList) {
      Object.assign(this, shoppingList);
      if (shoppingList.createdAt) {
        this.createdAt = moment(shoppingList.createdAt);
      }
    }
  }

  public get isOpen(): boolean {
    return this.orderId === null;
  }

  public get isClosed(): boolean {
    return this.orderId !== null;
  }

  public addRow(): ShoppingListRow {
    if (!this.rows) {
      this.rows = new Array();
    }

    const row = new ShoppingListRow();
    this.rows.push(row);
    return row;
  }

  public removeRow(rowNumber: number) {
    this.rows.splice(rowNumber, 1);
  }
}
