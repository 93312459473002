
import { Options, Vue } from "vue-class-component";
import { Modal } from "bootstrap";
import { ShoppingList, Order } from "../classes";
import { ShoppingListService, OrderService } from "../services";

interface ModalContent {
  title?: string;
  body: string;
  err: string | null;
}

@Options({})
export default class CreateOrderView extends Vue {
  public submittingOrder: boolean = false;

  public modalContent: ModalContent = {
    title: "",
    body: "",
    err: null,
  };

  public order: Order = new Order();
  public shoppingList: ShoppingList | null = null;

  private shoppingListService: ShoppingListService = new ShoppingListService();
  private orderService: OrderService = new OrderService();

  private infoModal!: Modal;

  public async mounted() {
    this.infoModal = new Modal(this.$refs.infoModal as HTMLElement);

    const id = parseInt(this.$route.query.shoppingList as string, 10) || -1;
    if (id === -1) {
      this.$router.push({
        name: "home",
      });
    }

    try {
      this.shoppingList = await this.shoppingListService.previewOne(id);
      this.order.shoppingList = this.shoppingList;
    } catch (err: any) {
      window.console.log(err);
      this.modalContent = {
        title: "Ooops!!!",
        body: "Något fel är trasigt... Vänta och testa igen, eller kontakta din lokala IT-kille...",
        err,
      };
      this.infoModal.show();
    }
  }

  public async submit() {
    try {
      this.submittingOrder = true;
      this.order = await this.orderService.create(this.order);

      this.modalContent = {
        body: "Ordern är skickad.",
        err: null,
      };
      this.infoModal.show();
    } catch (err: any) {
      this.modalContent = {
        title: "Ooops!!!",
        body: "Något fel är trasigt... Vänta och testa igen, eller kontakta din lokala IT-kille...",
        err,
      };
      this.infoModal.show();

      window.console.log(err);
    } finally {
      this.submittingOrder = false;
    }
  }

  public closeModal() {
    this.infoModal.hide();
  }

  public showOrder() {
    this.infoModal.hide();
    this.$router.push({
      name: "view-order",
      params: {
        id: this.order.id,
      },
    });
  }
}
