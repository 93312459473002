
import { Options, Vue } from "vue-class-component";
import { ShoppingList } from "../classes";
import { ShoppingListService } from "../services";

@Options({})
export default class DeleteShoppingListView extends Vue {
  public shoppingList: ShoppingList | null = null;

  private shoppingListService: ShoppingListService = new ShoppingListService();

  public mounted() {
    let id = parseInt(this.$route.params.id as string, 10) || -1;
    if (id === -1) {
      this.$router.push({ name: "home" });
    }

    this.shoppingListService
      .getOne(id)
      .then((shoppingList) => {
        this.shoppingList = shoppingList;
      })
      .catch((err) => {
        window.console.log(err);
      });
  }

  async deleteShoppingList(shoppingList: ShoppingList | null) {
    if (!shoppingList) {
      return;
    }

    await this.shoppingListService.delete(shoppingList);
    this.$router.push({ name: "home" });
  }
}
