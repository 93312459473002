
import { Options, Vue } from "vue-class-component";
import { ShoppingList } from "../classes";
import { ShoppingListService } from "../services";
import moment from "moment";
import "moment/locale/sv";

@Options({})
export default class CreateShoppingListView extends Vue {
  shoppingListName: string = "";
  sourceShoppingList: ShoppingList | null = null;

  private shoppingListService: ShoppingListService = new ShoppingListService();

  public mounted() {
    this.shoppingListName = this.newShoppinglistName();

    let sourceShoppingListId = 0;

    if (this.$route.params.shoppingList) {
      this.sourceShoppingList = this.$route.params
        .shoppingList as unknown as ShoppingList;
      sourceShoppingListId = this.sourceShoppingList.id;
    } else if (this.$route.query.sourceShoppingList) {
      try {
        sourceShoppingListId = parseInt(
          this.$route.query.sourceShoppingList as string,
          10
        );
      } catch (err) {
        window.console.log(err);
      }
    }

    if (sourceShoppingListId !== 0) {
      this.shoppingListService
        .getOne(sourceShoppingListId)
        .then((shoppingList) => {
          this.sourceShoppingList = shoppingList;
        })
        .catch((err) => {
          window.console.log(err);
        });
    }
  }

  async createNewShoppingList() {
    if (this.shoppingListName.length <= 0) {
      return;
    }

    let shoppingList: ShoppingList;

    if (this.sourceShoppingList === null) {
      shoppingList = await this.shoppingListService.create(
        this.shoppingListName
      );
    } else {
      shoppingList = await this.shoppingListService.copy(
        this.shoppingListName,
        this.sourceShoppingList
      );
    }

    this.$router.push({
      name: "view-shopping-list",
      params: {
        id: `${shoppingList.id}`,
      },
    });
  }

  private newShoppinglistName(): string {
    const nextWeek = moment().locale("sv").add(7, "days");
    return `Vecka ${nextWeek.isoWeek()}`;
  }
}
