
import { Options, Vue } from "vue-class-component";
import { Order, ShoppingList } from "../classes";
import { ShoppingListService, OrderService } from "../services";

@Options({})
export default class HomeView extends Vue {
  loading: boolean = false;
  shoppingLists: ShoppingList[] = [];
  orders: Order[] = [];

  private shoppingListService: ShoppingListService = new ShoppingListService();
  private orderService: OrderService = new OrderService();

  public async mounted() {
    this.loading = true;

    [this.shoppingLists, this.orders] = await Promise.all([
      this.shoppingListService.getAll(),
      this.orderService.getAll(),
    ]);
    this.loading = false;
  }

  viewShoppingList(shoppingList: ShoppingList) {
    this.$router.push({
      name: "view-shopping-list",
      params: {
        id: `${shoppingList.id}`,
      },
    });
  }

  viewOrder(order: Order) {
    this.$router.push({
      name: "view-order",
      params: {
        id: `${order.id}`,
        order: JSON.stringify(order),
      },
    });
  }
}
