
import { Options, Vue } from "vue-class-component";
import { Order, ShoppingList } from "../classes";
import { OrderService, ShoppingListService } from "../services";

import { Modal } from "bootstrap";

interface ModalContent {
  body: string;
  err: string | null;
}

@Options({})
export default class OrderView extends Vue {
  shoppingList: ShoppingList | null = null;
  resubmitting: boolean = false;

  modalContent: ModalContent = {
    body: "",
    err: null,
  };

  order: Order | null = null;

  private orderService: OrderService = new OrderService();
  private shoppingListService: ShoppingListService = new ShoppingListService();

  private infoModal!: bootstrap.Modal;

  public async mounted() {
    this.infoModal = new Modal(this.$refs.infoModal as HTMLElement);

    const id = parseInt(this.$route.params.id as string, 10) || -1;
    if (id === -1) {
      this.$router.push({
        name: "home",
      });
    }

    try {
      this.order = await this.orderService.getOne(id);
    } catch (err: any) {
      window.console.log(err);
      this.modalContent = {
        body: "Kunde inte ladda ordern... :(",
        err: err,
      };
      this.infoModal.show();
    }

    if (this.order && this.order.shoppingList) {
      this.shoppingList = await this.shoppingListService.getOne(
        this.order.shoppingList.id
      );
    }
  }

  closeModal() {
    this.infoModal.hide();
  }

  async resubmit() {
    if (this.order) {
      try {
        this.resubmitting = true;
        this.order = await this.orderService.resubmit(this.order);

        this.modalContent = {
          body: "Ordern är skickad.",
          err: null,
        };
        this.infoModal.show();
      } catch (err: any) {
        this.modalContent = {
          body: "Kunde inte skicka ordern... :(",
          err: err,
        };
        this.infoModal.show();
      } finally {
        this.resubmitting = false;
      }
    }
  }
}
